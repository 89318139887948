import { Grid, Link, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Grid
      className="bg-black text-white mt-10 text-center flex justify-between items-center px-4 "
      color={"white"}
      sx={{ bgcolor: "black", color: "white", py: 3 }}
    >
      <div className="flex flex-col items-start ">
        <div className="w-full">
          <iframe
            width="300"
            height="200"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=400&amp;height=240&amp;hl=en&amp;q=Sanskruti%20A/C%20market+(Shree%20Sai%20Baba%20Textiles)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
        <Typography variant="body2" component="p" gutterBottom>
          Sanskruti A.C. Market, Godadara Magob, Surat, Gujarat, India
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Zip code - 395010
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Mobile +91-8401825745
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Email shreesaibabatex@gmail.com
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Working Hours (Mon - Sat) 09:00 - 20:00
        </Typography>
      </div>
      <Grid item xs={12} sm={6} md={3}>
        <Typography className="pb-5" variant="h6" gutterBottom>
          Company
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          About
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Blog
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Jobs
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Press
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Partners
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography className="pb-5" variant="h6" gutterBottom>
          Solutions
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Marketing
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Analytics
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Commerce
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Insights
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Support
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Typography className="pb-5" variant="h6" gutterBottom>
          Legal
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Claim
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Privacy
        </Typography>
        <Typography variant="body2" component="p" gutterBottom>
          Terms
        </Typography>
      </Grid>
      <div className="pt-20" item xs={12}></div>
    </Grid>
  );
};

export default Footer;
