export const lengha_page1 = [
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/u/g/a/free-half-sleeve-vbnd-fddj-astonblue-original-imagp286bmcaabbj.jpeg?q=70",
    "brand": "AstonBlue",
   "title": "Self Design Semi Stitched Lehenga Choli",
    "color": "Yellow",
    discountedPrice: "₹35,999",
    price: "₹39,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "10% off",
    description:
      "Yellow Readymade Lehenga in Net fabric  The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupattaComes with the Koskii promise of premium quality",
      "topLavelCategory": "Women",
      "secondLavelCategory": "Clothing",
      "thirdLavelCategory": "lengha_choli",
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/kmz7qfk0/lehenga-choli/m/0/t/s-sleeveless-l032-rayie-collection-original-imagfrbqhvddrnbd.jpeg?q=70",
    "brand": "rayie collection",
   "title": "Embroidered Stitched Lehenga & Crop Top",
    "color": "Black",
    discountedPrice: "₹19,124",
    price: "₹35,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "46% off",
    description:
    "Yellow Readymade Lehenga in Net fabric  The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupattaComes with the Koskii promise of premium quality",
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/5/n/h/l-3-4-sleeve-black-liva-original-imagmeyw2zzbch2g.jpeg?q=70",
    "brand": "Liva",
   "title": "Embroidered Stitched Lehenga & Crop Top",
    "color": "Black",
    discountedPrice: "₹13,999",
    price: "₹20,000",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "30% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/b/h/z/xl-sleeveless-owa2cd2320a-soch-original-imagjvmyhy63ms7z.jpeg?q=70",
    "brand": "soch",
   "title": "Embellished Stitched Lehenga Choli",
    "color": "Yellow",
    discountedPrice: "₹12,998",
    price: "",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "",
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/y/d/8/l-3-4-sleeve-green-liva-original-imagmez3duzyzzxz.jpeg?q=70",
    "brand": "Liva",
   "title": "Embroidered Stitched Lehenga & Crop Top",
    "color": "Green",
    discountedPrice: "₹9,999",
    price: "₹18,000",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "44% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/j/b/g/xl-sleeveless-owa2cd2319a-soch-original-imagjvmysh3z9egk.jpeg?q=70",
    "brand": "soch",
   "title": "Solid Stitched Lehenga Choli",
    "color": "Dark Blue",
    discountedPrice: "₹9,998",
    price: "",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "30% off",
  },
 
{
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/l16rde80/lehenga-choli/h/r/t/free-3-4-sleeve-r1-5-aminia-original-imagct7ghhpgrm48.jpeg?q=70",
    "brand": "AMINIA",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Maroon",
    discountedPrice: "₹8,600",
    price: "₹10,950",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "21% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/r/8/u/free-half-sleeve-mjo4061-mjo-fashion-original-imagmyk9yxpykc7j.jpeg?q=70",
    "brand": "mjo fashion",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Maroon",
    discountedPrice: "₹7,999",
    price: "₹9,550",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "16% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/3/d/1/l-half-sleeve-na-lh-mamta-fashion-hub-original-imagz6fhx79zcy7g.jpeg?q=70",
    "brand": "rashora",
   "title": "Self Design Semi Stitched Lehenga Choli",
    "color": "Gold, Orange",
    discountedPrice: "₹7,999",
    price: "₹9,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "20% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/sari/j/f/n/free-verveecouture-vv-126-vervee-couture-unstitched-original-imagnhgjmgrth4xr.jpeg?q=70",
    "brand": "Vervee Couture",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Blue",
    discountedPrice: "₹7,629",
    price: "₹15,239",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "49% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/z/c/c/free-short-sleeve-sochncugc5604-soch-original-imaghy7nebe4jzhn.jpeg?q=70",
    "brand": "soch",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Maroon",
    discountedPrice: "₹6,999",
    price: "₹13,998",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "50% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/m/d/c/free-short-sleeve-women-satin-red-lehenga-choli-set-for-wedding-original-imagn8kzjrfakvsu.jpeg?q=70",
    "brand": "kajtosh",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Red",
    discountedPrice: "₹6,599",
    price: "₹33,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "80% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/3/c/l/m-sleeveless-owa2cd2127a-soch-original-imagg4543hg6dmjs.jpeg?q=70",
    "brand": "soch",
   "title": "Embroidered Stitched Lehenga Choli",
    "color": "Green",
    discountedPrice: "₹5,999",
    price: "₹14,998",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "60% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/f/w/r/m-short-sleeve-35072-libas-original-imaghqz3fu82bd8x.jpeg?q=70",
    "brand": "LIBAS",
   "title": "Solid Stitched Lehenga Choli",
    "color": "Green",
    discountedPrice: "₹5,439",
    price: "₹7,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "32% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/3/a/w/l-3-4-sleeve-heavy-lehenga-with-blouse-and-dupatta-perhaps-original-imaggzgehtruqhhc.jpeg?q=70",
    "brand": "PERHAPS",
   "title": "Embroidered Stitched Lehenga & Crop Top",
    "color": "Black, Grey",
    discountedPrice: "₹5,240",
    price: "₹13,000",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "59% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/b/g/a/xl-half-sleeve-heavy-work-lehenga-choli-with-dupatta-perhaps-original-imagjbjeavb5hvag.jpeg?q=70",
    "brand": "PERHAPS",
   "title": "Embroidered, Embellished Stitched Lehenga Choli",
    "color": "Multicolor",
    discountedPrice: "₹5,000",
    price: "₹13,000",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "61% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/i/5/p/free-half-sleeve-1fvv-lc-3042-sky-blue-fabviva-original-imagkcazapqjnjzj.jpeg?q=70",
    "brand": "Bhavyam",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Light Blue",
    discountedPrice: "₹4,999",
    price: "₹14,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "66% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/x/9/u/xxl-sleeveless-madhu-niza-fashion-original-imagmhv56qruwscu.jpeg?q=70",
    "brand": "Shivanya Fashion",
   "title": "Embellished Stitched Lehenga Choli",
    "color": "Purple",
    discountedPrice: "₹4,999",
    price: "₹9,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "50% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/l5ld8y80/lehenga-choli/y/x/2/free-half-sleeve-br-lc-3042-sky-blue-blousary-original-imagg8e76vzjkfrz.jpeg?q=70",
    "brand": "Bhavyam",
   "title": "Embroidered, Self Design Semi Stitched Lehenga Choli",
    "color": "Light Blue, Multicolor",
    discountedPrice: "₹4,999",
    price: "₹14,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "66% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/v/o/q/free-half-sleeve-1fvv-lc-3042-peach-fabviva-original-imagkcadgmfdp9vu.jpeg?q=70",
    "brand": "VAMSE",
   "title": "Embellished Semi Stitched Lehenga Choli",
    "color": "Pink",
    discountedPrice: "₹4,999",
    price: "₹14,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "66% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/1/c/n/free-3-4-sleeve-2lehnga06-abcd-original-imagzvnna4jhfywz.jpeg?q=70",
    "brand": "ABCD",
   "title": "Self Design Semi Stitched Lehenga Choli",
    "color": "Multicolor",
    discountedPrice: "₹4,920",
    price: "₹8,000",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "38% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/v/e/g/free-short-sleeve-kajal-niza-fashion-original-imagnrhz6faqfhgr.jpeg?q=70",
    "brand": "Shivanya Fashion",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Green",
    discountedPrice: "₹4,599",
    price: "₹8,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "48% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/l3j2cnk0/lehenga-choli/x/d/x/free-half-sleeve-1ad-lc-3003-adbhutam-original-imageUrlmr2hhgktgcr.jpeg?q=70",
    "brand": "Bhavyam",
   "title": "Embroidered, Self Design Semi Stitched Lehenga Choli",
    "color": "Blue",
    discountedPrice: "₹4,599",
    price: "₹11,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "61% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/m/f/v/free-half-sleeve-1fvv-lc-3003-fabviva-original-imagkb5hsadb4gc3.jpeg?q=70",
    "brand": "Bhavyam",
   "title": "Embroidered, Self Design Semi Stitched Lehenga Choli",
    "color": "Blue",
    discountedPrice: "₹4,599",
    price: "₹11,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "61% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/6/y/y/free-na-ha2301-hathka-original-imagzxzswr7kaerv.jpeg?q=70",
    "brand": "Hathka",
   "title": "Self Design Semi Stitched Rajasthani Poshak",
    "color": "Purple, Yellow",
    discountedPrice: "₹4,550",
    price: "₹5,500",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "17% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/shopsy-lehenga-choli/n/9/m/free-full-sleeve-semi-stitched-46-s0426wd-navy-blue-lehenga-original-imagkzfgszuewujf.jpeg?q=70",
    "brand": "CAMPAIGN TRENDS",
   "title": "Self Design Semi Stitched Lehenga Choli",
    "color": "Blue",
    discountedPrice: "₹4,549",
    price: "₹15,499",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "70% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/a/9/5/xxl-sleeveless-xxl-kiya-original-imaggjn8f6raargz.jpeg?q=70",
    "brand": "KIYA",
   "title": "Embellished Stitched Lehenga Choli",
    "color": "Black",
    discountedPrice: "₹4,504",
    price: "₹13,239",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "65% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/k/i/p/free-na-paithani-lehenga-choli-geetanjali-original-imagp32udetp8kek.jpeg?q=70",
    "brand": "Geetanjali",
   "title": "Embellished Semi Stitched Lehenga Choli",
    "color": "Yellow",
    discountedPrice: "₹4,500",
    price: "₹10,000",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "55% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/e/j/0/free-half-sleeve-na-d-no-810-r-rajshree-fashion-fab-original-imagzymbh7qz48y4.jpeg?q=70",
    "brand": "Rajshree Fashion Fab",
   "title": "Embroidered, Embellished Semi Stitched Lehenga Choli",
    "color": "Red",
    discountedPrice: "₹4,500",
    price: "₹9,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "54% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/t/9/m/free-na-paithani-lehenga-choli-geetanjali-original-imagp33yscpevubp.jpeg?q=70",
    "brand": "Geetanjali",
   "title": "Embellished Semi Stitched Lehenga Choli",
    "color": "Orange",
    discountedPrice: "₹4,500",
    price: "₹10,000",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "55% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/o/9/i/4xl-short-sleeve-dk0819-divena-original-imagjwp9mh2urcqg.jpeg?q=70",
    "brand": "DIVENA",
   "title": "Printed Stitched Lehenga Choli",
    "color": "Red",
    discountedPrice: "₹4,499",
    price: "₹11,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "62% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/e/z/5/free-short-sleeve-sochncugc5600a-soch-original-imaghy7nkvuhrhvy.jpeg?q=70",
    "brand": "soch",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Red",
    discountedPrice: "₹4,499",
    price: "₹8,998",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "50% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/9/y/d/free-na-lnb1251nbl-looknbook-art-original-imaghu99pfzmrsgk.jpeg?q=70",
    "brand": "LookNbook art",
   "title": "Printed Semi Stitched Lehenga Choli",
    "color": "Dark Blue",
    discountedPrice: "₹4,499",
    price: "₹5,400",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "16% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/m/5/t/xl-short-sleeve-this-beautiful-embroidered-lehenga-choli-with-original-imagg9j7dncbkahu.jpeg?q=70",
    "brand": "FESTIVE SHOPEE",
   "title": "Embroidered, Embellished, Self Design Stitched Lehenga ...",
    "color": "Blue",
    discountedPrice: "₹4,499",
    price: "₹7,449",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "39% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/shopsy-lehenga-choli/j/s/n/free-full-sleeve-semi-stitched-46-s0510gd-wine-lehenga-choli-original-imagkzdzvwgjwwuh.jpeg?q=70",
    "brand": "CAMPAIGN TRENDS",
   "title": "Self Design Semi Stitched Lehenga Choli",
    "color": "Purple",
    discountedPrice: "₹4,399",
    price: "₹14,799",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "70% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/km6mxe80/lehenga-choli/f/i/i/free-na-velvetqn4m-manvaa-original-imagf59gy7sbxmjs.jpeg?q=70",
    "brand": "Shadow & Saining",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Maroon",
    discountedPrice: "₹4,389",
    price: "₹17,196",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "74% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/km6mxe80/lehenga-choli/v/w/d/free-na-velvetqn5m-manvaa-original-imagf59mqaxpe9yh.jpeg?q=70",
    "brand": "Shadow & Saining",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Maroon",
    discountedPrice: "₹4,389",
    price: "₹17,196",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "74% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/km6mxe80/lehenga-choli/s/l/l/free-na-mrsvelvetqn6m-maroosh-original-imagf59z88g5g8mh.jpeg?q=70",
    "brand": "Shadow & Saining",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Maroon",
    discountedPrice: "₹4,389",
    price: "₹17,196",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "74% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/km6mxe80/lehenga-choli/7/h/r/free-na-mrsvelvetqn3m-maroosh-original-imagf59hjfrgev7y.jpeg?q=70",
    "brand": "Shadow & Saining",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Maroon",
    discountedPrice: "₹4,389",
    price: "₹17,196",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "74% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
  {
    "imageUrl":
      "https://rukminim1.flixcart.com/imageUrl/612/612/xif0q/lehenga-choli/m/i/a/free-na-ps-set-121-mode-connection-original-imagpf383nyhz8nh.jpeg?q=70",
    "brand": "Love Purple",
   "title": "Embroidered Semi Stitched Lehenga Choli",
    "color": "Black",
    discountedPrice: "₹4,299",
    price: "₹5,999",
    size: [
      {
        name: "S",
        quantity: 20,
      },
      {
        name: "M",
        quantity: 30,
      },
      {
        name: "L",
        quantity: 50,
      },
    ],
    "discountPersent": "28% off",
    description:"The Lehenga is embellished with Mirrorwork embroidery Accompanied with an Readymade blouse and dupatta Comes with the Koskii promise of premium quality"
  },
];
